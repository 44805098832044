<template>
  <div class="row">
    <ElFormItem
      v-if="mode !== modes.add"
      class="col-12"
      :prop="resolveFormItemProp('video')"
      :rules="{ validator: validateVideoUrl }"
    >
      <SdFloatLabel>
        <ElTooltip
          :visible-arrow="false"
          :disabled="$viewport.lt.md"
          popper-class="popover-panel"
          placement="right-start"
        >
          <template slot="content">
            <i class="sdicon-info-circle" />
            <p>This link will be used when enabling properties for showing.</p>
          </template>
          <SdFloatLabel>
            <ElInput
              v-model="unit.video"
              placeholder="Video URL"
              :disabled="isDisabled"
              @input="(value) => $emit('update-unit', {video: value})"
            />
          </SdFloatLabel>
        </ElTooltip>
      </SdFloatLabel>
    </ElFormItem>
    <div
      v-if="!isDisabled && imageGallery.length > 0"
      :class="mode === modes['activate_showing'] ? 'mt-4': ''"
      class="col-12"
    >
      <div class="font-17 font-weight-bold">
        Image Gallery
      </div>
      <div>
        You can drop (or browse to select) multiple photos at a time using the uploader below.
        Once uploaded, you can drag and drop the images to change their order.
      </div>
    </div>
    <Draggable
      v-if="imageGallery.length > 0"
      :key="`media-gallery-${isUploadingGallery ? 'uploading' : 'upload'}`"
      v-model="imageGallery"
      :animation:="500"
      :disabled="isUploadingGallery || imageGallery.length < 2"
      :ghost-class="isUploadingGallery ? '' : 'dragging-ghost-class'"
      class="col-12"
      :class="[
        isUploadingGallery ? 'draggable-uploading' : 'draggable-upload',
        isDisabled ? 'draggable-disabled' : '',
        mode === modes['activate_showing'] ? 'pt-4 mt-3' : 'mt-2'
      ]"
      @start="dragging = true"
      @end="onEndDrag"
    >
      <transition-group
        type="transition"
        :name="!dragging ? 'reorder' : null"
        class="row"
      >
        <div
          v-for="(image, imageIndex) in imageGallery"
          :key="`image-${image.src}`"
          :class="[
            isDisabled ? 'image-container-disabled' : '',
            'image-container',
            'd-flex',
            'align-items-center',
            'justify-content-center',
            {
              'image-container-draggable': !isUploadingGallery && imageGallery.length > 1,
              'col-4 col-lg-4': !showHeroImage || imageIndex > 0,
              'col-12': showHeroImage && imageIndex === 0
            }
          ]"
        >
          <img :src="image.src">
          <div
            v-if="image.isUploading"
            class="image-upload-spinner d-flex text-center justify-content-center align-items-center font-15 text-white"
          >
            <ElSpinner
              :radius="36"
              color="primary"
            />
          </div>

          <div
            v-if="image.uploadError"
            class="image-upload-error d-flex text-center justify-content-center align-items-center font-15 text-white"
          >
            {{ "Upload Error\nPlease try re-uploading this image" }}
          </div>
          <div
            v-if="image.uploadResponse"
            class="image-upload-success d-flex text-center justify-content-center align-items-center font-15 text-white"
          >
            <i class="sdicon-check font-42 text-white" />
          </div>
          <div
            v-if="!isDisabled"
            class="remove-img-btn-top-right"
            @click="() => removeImage(imageIndex)"
          >
            <i class="sdicon-trash font-17 text-white" />
          </div>
        </div>
      </transition-group>
    </Draggable>
    <div
      v-if="imageGallery.length < maxImages && !isDisabled"
      class="col-12 image-input-wrapper"
      :class="[mode === modes['activate_showing'] ? 'mt-4' : '']"
      @drop.prevent="event => handleDrop(0, event)"
    >
      <input
        type="file"
        multiple
        accept="image/png, image/jpeg, image/jpg"
        class="image-input"
        @change="event => handleFiles(event, 0, 'browse')"
      >
      <div class="image-upload-placeholder p-3 text-center">
        <i class="font-42 text-gray-dark sdicon-plus image-upload-plus-icon" />
        <div class="text-center font-17">
          {{ "Add images for listing\n(Drop images here or click to browse)" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

import Draggable from 'vuedraggable';
import regexConstants from '@/constants/Regex';
import get from 'lodash.get';

const modes = { add: 'ADD', edit: 'EDIT', activate_showing: 'ACTIVATE_SHOWING' };
export default {
  name: 'UnitMediaGallery',
  components: {
    Draggable,
  },
  props: {
    unit: {
      type: Object,
      required: false,
      default: null,
    },
    // TODO: fix showHeroImage to respect the hero image and provide a better UX for it
    // If we adding hero image - we must add :list="imageGallery" to draggable component, making the transitions buggy
    // See https://github.com/SortableJS/Vue.Draggable/issues/1097
    showHeroImage: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxImages: {
      type: Number,
      required: false,
      default: 50,
    },
    propertyUnitIndex: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      validator: (mode) => Object.values(modes).includes(mode),
      required: true,
    },
    listingDataPrefill: {
        type: Object,
        default: null,
    },
  },
  watch: {
    listingDataPrefill(newVal) {
      this.refreshData(newVal);
    },
  },
  setup(props, context) {
    const dragging = ref(false);
    const isUploadingGallery = ref(false);
    const dispatch = context.root.$store.dispatch;

    const unitImages = get(props, 'unit.images', null);
    const listingImages = get(props, 'listingDataPrefill.images', null);

    const imageGallery = ref((listingImages || unitImages || []).map((image) => ({ src: image })));
    const unitVideo = get(props, 'unit.video', null);
    const listingVideo = get(props, 'listingDataPrefill.video', null);
    props.unit.video = listingVideo || unitVideo;

    const isIntegrationEnabled = context.root.$store.getters['BusinessSource/isIntegrationEnabled'];
    const isSyndicated = context.root.$store.getters['Auth/businessSyndicated'];
    const isDisabled = isIntegrationEnabled && !isSyndicated && (Boolean(props.unit.listing_identifier) || Boolean(props.unit.origin_id));
    return {
      isDisabled,
      modes,
      isUploadingGallery,
      handleDrop,
      dragging,
      onEndDrag,
      imageGallery,
      handleFiles,
      removeImage,
      uploadImages,
      setUnitImagesField,
      validateVideoUrl,
      resolveFormItemProp,
      refreshData,
    };

    function refreshData(freshData) {
      if (freshData.images?.length) {
        imageGallery.value = freshData.images.map((image) => ({ src: image }));
      }
    }

    function setUnitImagesField(nextUnitImagesField) {
      props.unit.images = nextUnitImagesField;
      context.emit('update-property-payload', { units: props.property?.units });
    }

    function appendSrcAndErrorToImageUpload({ src, error }, imageIndex) {
      const updatedImages = [...imageGallery.value];
      updatedImages[imageIndex].uploadResponse = src;
      updatedImages[imageIndex].uploadError = error;
      imageGallery.value = updatedImages;
      return src || error;
    }

    function removeImage(indexToRemove) {
      const updatedImages = [...imageGallery.value].filter((_, index) => index !== indexToRemove);
      imageGallery.value = updatedImages;
      setUnitImagesField(updatedImages);
    }

    function onEndDrag(d, x) {
      dragging.value = false;
      setUnitImagesField(imageGallery.value);
    }

    function handleFiles(event, index, type) {
      const files = type === 'drop' ? Array.from(event.dataTransfer?.files) : Array.from(event.target?.files);
      Promise.all(files.map((file) => resizeAndPreviewImage(file))).then((resizedImages) => {
        let updatedImages = [...imageGallery.value];
        resizedImages.forEach((resizedImage, fileIndex) => {
          const newImage = {
            name: resizedImage.name,
            src: URL.createObjectURL(resizedImage),
            raw: resizedImage,
          };

          const targetIndex = index + fileIndex;
          if (targetIndex < updatedImages.length && (updatedImages[targetIndex] === null || updatedImages[targetIndex]?.src === null)) {
            updatedImages[targetIndex] = newImage;
          } else if (targetIndex < props.maxImages) {
            updatedImages.push(newImage);
          }
        });
        updatedImages = updatedImages.slice(0, props.maxImages);
        imageGallery.value = updatedImages;
        setUnitImagesField(updatedImages);
      });
    }

    function handleDrop(index, event) {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        handleFiles(event, index, 'drop');
      }
    }

    async function uploadImages() {
      isUploadingGallery.value = true;
      if (!imageGallery.value.length) {
        return [];
      }

      const allUnitsImageUploadPromises = [];
      imageGallery.value.forEach((image, imageIndex) => {
        const uploadPromise = uploadSingleImage(image, imageIndex);
        allUnitsImageUploadPromises.push(uploadPromise);
      });

      return Promise.all(allUnitsImageUploadPromises)
        .then((images) => {
          setUnitImagesField(images);
          return images;
        })
        .finally(() => {
          isUploadingGallery.value = false;
        });
    }

    async function uploadSingleImage(image, imageIndex) {
      const updatedImages = [...imageGallery.value];
      updatedImages[imageIndex].uploadError = null;
      updatedImages[imageIndex].isUploading = true;
      try {
        const isSrcExisitsOnServer = image?.src?.startsWith?.('http');
        if (isSrcExisitsOnServer) {
          return appendSrcAndErrorToImageUpload({ src: image.src, error: null }, imageIndex);
        }

        if (image.uploadResponse && !image.uploadError) {
          return appendSrcAndErrorToImageUpload({ src: image.uploadResponse, error: null }, imageIndex);
        }

        const formData = new FormData();
        formData.append('file', image.raw);
        const response = await dispatch('Property/uploadPicture', formData);
        if (response?.url) {
          return appendSrcAndErrorToImageUpload({ src: response.url, error: null }, imageIndex);
        }

        throw new Error('Upload error', response);
      } catch (error) {
        return appendSrcAndErrorToImageUpload({ src: null, error }, imageIndex);
      } finally {
        updatedImages[imageIndex].isUploading = false;
      }
    }

    function resizeAndPreviewImage(file, maxHeight = 1080) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onerror = reject;
        img.onload = () => {
          const height = img.height > maxHeight ? maxHeight : img.height;
          const width = img.width * (height / img.height);
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, { type: file.type }));
          }, file.type);
        };
      });
    }

    function validateVideoUrl(rule, value, callback) {
      if (!value) {
        return callback();
      }
      if (!value.match(regexConstants.url)) {
        return callback(new Error('Video URL is not valid'));
      }
      if (!value.match(regexConstants.http_protocol)) {
        props.unit.video = `https://${value}`;
      }
      return callback();
    }

    function resolveFormItemProp(key) {
      const prop = `units.${props.propertyUnitIndex}.${key}`;
      if (props.mode === modes.add) {
        return prop;
      }
      if (props.mode === modes.edit) {
        return `property.${prop}`;
      }
      return key;
    }
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 180px;
  position: relative;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-upload-spinner {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    background: rgba(255, 255, 255, 0.45);
    left: 10px;
  }

  .image-upload-error {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    background: rgba(255, 0, 0, 0.65);
    left: 10px;
  }
  .image-upload-success {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    transition: background-color 0.25s ease-out;
    background-color: rgba(theme-color("greeneyes"), 0.5);
  }
}

.draggable-uploading {
  pointer-events: none;
  user-select: none;
}
.draggable-disabled {
  pointer-events: none;
}
.image-container-draggable {
  cursor: move;
}

.image-input-wrapper {
  &:hover {
    .image-upload-placeholder {
      border-color: gray-color("dark");
    }
  }
}
.image-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.image-upload-placeholder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(gray-color("lighter"), 0.25);
  border: 2px dashed gray-color("");
  transition: all 0.25s ease;
  white-space: pre;
}
.image-upload-plus-icon {
  display: flex;
  align-items: center;
  height: 42px;
}

.dragging-ghost-class {
  opacity: 0.5;
  background: rgba(theme-color("primary"), 0.5);
}

.remove-img-btn-top-right {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 15px;
  right: 20px;
  background-color: theme-color("primary");
  transition: background-color 0.25s ease-out;
  cursor: pointer;
  &:hover,
  &:active {
    background-color: rgba(theme-color("primary-dark"), 0.85);
  }
}

.image-container-disabled {
  opacity: 0.75;
  cursor: not-allowed;
}
</style>
